import imgLogo from '@/assets/icons/logo.png';
import buildingSvg from '@/assets/bg/building-crop.svg';
import buildingInvertSvg from '@/assets/bg/building.svg';


// BG Images
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/bg-desktop.jpg?updatedAt=1705460193347`;
export const BG_INSTAGRAM_FILTER = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Instagram%20Filter.jpg?updatedAt=1705456224706`;
export const BG_QR_INVITATION= `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/bg-qr-invitation.jpg?updatedAt=1705460488472`;

// Welcoming Image
// @TODO: check this image used or not?
export const IMG_PHOTO_1 = ``;
export const IMG_PHOTO_2 = ``;
export const IMG_PHOTO_3 = ``;

// Couple Image
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const IMG_MAN = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/groom.jpg?updatedAt=1705456181484`;
export const IMG_GIRL = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/bride.jpg?updatedAt=1705456148036`;

// Cover Gallery Image
// @TODO: check this image is used or not
export const IMG_GALLERY_1 = `https://ik.imagekit.io/adelinejune/template-cjforcisty/gallery.jpg?updatedAt=1696478075546`;
export const IMG_GALLERY_COVER = IMG_GALLERY_1;

// Logo Section
export const IMG_LOGO = imgLogo;
// @TODO: check this image is used or not
export const IMG_LOGO_INVERT = IMG_LOGO;

/**
 * Image Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */

export const ASSETS_RIBBON_PINK = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Ribbon%20Pink.png?updatedAt=1704739241454`
export const ASSETS_RIBBON_BLUE = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Ribbon%20Blue.png?updatedAt=1705034877992`
export const ASSETS_FRAME = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Frame.png?updatedAt=1704739237908`
export const ASSETS_BUILDING = buildingSvg;
export const ASSETS_BUILDING_INVERT = buildingInvertSvg;
export const ASSETS_BUTTERFLY = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Butterfly.png?updatedAt=1704739237289`
export const ASSETS_LEAF = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Leaf.png?updatedAt=1704739237114`
export const ASSETS_PAPER_BG = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Paper%20Background.png?updatedAt=1704739237621`
export const ASSETS_FLOWER_1 = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Flower%201.png?updatedAt=1704739237623`
export const ASSETS_FLOWER_2A = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Flower%202.png?updatedAt=1704739237240`
export const ASSETS_FLOWER_2B = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Flower%202a.png?updatedAt=1704739237437`
export const ASSETS_FLOWER_3 = `https://ik.imagekit.io/kmiidbviy/invitato/template-astriakbar/Flower%203.png?updatedAt=1704739237606`


/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
